
body {
    background-color: #333333;
    .mini-header{
        font-size: 9px;
        margin-left: 10px;
    }
    .progress-background{
        background-image: url("../../../images/backgrounds/green.png");
        background-repeat: no-repeat;
    }
    .border-highlight{
        border: 5px solid #003400;
    }
    .scroll-horizontal{
        overflow-x: hidden;
        overflow-y: hidden;
        width: 150%;
        min-height: calc(100vh - 564px);
        Col{
            display: inline-block;
        }
    }

    -webkit-user-select: none;      
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

body:not(.modal-open) {
    padding-right: 0px !important;
    margin-right: 0px !important;
}

.img-icon {
    height: 26px;
}

.spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 1;
    z-index: 1000;

    .content-spinner {
        display: flex;
        background-color: white;
        padding: 10px;
        border: 5px solid green;
        border-radius: 24px;      
        width: 363px;
        height: 119px;  
        img {
            width: 90px;
        }
        .spinner-text {
            padding-left: 20px;
            font-size: $font-size-h3;
            margin: auto;
        }
    }

    .spinner-border {
        width: 3.5rem;
        height: 3.5rem;
        color: $dark-green;
    }

}

.modal-content {
    .spinner {
        position: absolute;
    }
}

.welldone {
    .modal-content {
        background-color: transparent;
        border: none;
        box-shadow: none;
        .title-activity {
            position: absolute;
            top: 317px;
            left: 250px;
            width: 523px;
            span {
                font-size: 1rem;
                font-weight: bold;
            }
        }
        .activity-skills {
            position: absolute;
            top: 385px;
            left: 475px;
            width: 320px;
            text-align: left;
            height: 96px;
            p {
                font-size: .8rem;
                margin-bottom: 1px;
            }
        }
    }
}

.block-ui {
    position: unset;
    z-index: 1051;
    + div {
        opacity: 0.5;
    }
    ~.wrapper {
        opacity: 0.5 !important;
    }
}

.warning {
    .title, .description {
        text-align: center;
        color: $warning-color !important;
    }

    .title {
        font-size: 4rem;
        font-weight: bold;
    }

    .description {
        font-size: 3rem;
    }     
}

.hide {
    display: none;
}

.break-wrap {
    flex-wrap: unset;
}

.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 10px;
}

.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}

.separator.separator-gray{
    background-color: #EEEEEE;
}

.separator.separator-transparent{
    background-color: transparent;
}

.link{
    text-decoration: none;
    cursor: pointer;
}

.content{
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    // box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.5);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: white;
}

.margin-lr-auto {
    margin-right: auto;
    margin-left: auto;
}

.margin-auto {
    margin: auto;
}

.content-empty {
    height: calc(100vh - 500px);
}