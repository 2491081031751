//
// Modals
// Now Ui Kit Design element Dialogs
// --------------------------------------------------
.modal-progress{
    .close{
        position: absolute;
        right: calc(100% - 75px) !important;
        top: 45px !important;
    }
    .username{
        background-color: #00ff03;
        text-align: center;
        border-radius: 25px;
        border: 3px solid #003c00;
        min-height: 50px;
        display: flex;
        justify-content: center;
        max-width: fit-content;
        padding: 5px 50px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .list-module{
        border: 3px solid #b2b1b2;
        border-radius: 10px;
        background-color: white;
        position: relative;
        padding-top: 15px;
        margin-bottom: 15px;
        .header{
            border: 3px solid #b2b1b2;
            border-radius: 10px;
            background-color: #fffe7f;
            position: absolute;
            top: -15px;
            left: 15px;
            padding: 0 10px;
        }
        .list-activity{
            margin: 0;
            font-size: 12px;
            font-weight: bold;
            list-style-type: none;
            padding-left: 5px;
            padding-bottom: 5px;
            width: 100%;
            li{
                position: relative;
                img{
                    height: 15px;
                    position: absolute;
                    right: 5px;
                }
            }
            span{
                font-weight: normal;
                color: #b4b4b4;
            }
        }
    }
}
.modal-content-yellow{
    .modal-content{ 
        border: 4px solid #fcfb7e;
        border-radius: 20px;
    }
    .modal-title{
        padding-top: 10px;
    }
    .border-red{
        border: 4px solid #ffc4ba;
        border-radius: 15px;
    }
    .border-white{
        border: 4px solid white;
        border-radius: 10px;
    }
}
.modal-content {
  background-color: $medium-gray;
  border-radius: $border-radius-modal;
  border: none;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
  // Modal header
  // Top section of the modal w/ title and dismiss
  .modal-header {
    border-bottom: none;
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 0;
    // padding-left:   24px;

    & button{
        // position: absolute;
        right: 27px;
        top: 30px;
        outline: 0;
    }
    .title{
        margin-top: 5px;
        margin-bottom: 0;
    }
  }
  // Modal body
  // Where all modal content resides (sibling of .modal-header and .modal-footer)
  .modal-body {
    padding-top:    24px;
    padding-right:  24px;
    padding-bottom: 16px;
    padding-left:   24px;
    line-height: 1.35;
  }
  // Footer (for actions)
  .modal-footer {
    border-top: none;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    // -webkit-justify-content: space-between; /* Safari 6.1+ */
    // justify-content: space-between;
    -webkit-justify-content: center;
    justify-content: center;

    button {
      margin: 0;
      padding-left: 16px;
      padding-right: 16px;
      width: auto;
      &.pull-left {
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        left: -5px;
      }
    }

  }
  .modal-body + .modal-footer {
    padding-top: 0;
  }

  border: 4px solid $dark-green;

  .modal-header {
    padding-bottom: 0px;
    display: unset;
  }
}

.modal-backdrop {
  //background: rgba(0,0,0,0.8);
}

.modal{

    &.modal-mini{
        p{
            text-align: center;
        }

        .modal-dialog{
            max-width: 255px;
            margin: 0 auto;
        }

        .modal-profile{
            width: 70px;
            height: 70px;
            background-color: $white-color;
            border-radius: 50%;
            text-align: center;
            line-height: 5.7;
            box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.3);

            i{
                color: $primary-color;
                font-size: 21px;
            }

            &[class*="modal-profile-"]{
                i{
                    color: $white-color;
                }
            }

            &.modal-profile-primary{
                background-color: $primary-color;
            }

            &.modal-profile-danger{
                background-color: $danger-color;
            }

            &.modal-profile-warning{
                background-color: $warning-color;
            }

            &.modal-profile-success{
                background-color: $success-color;
            }

            &.modal-profile-info{
                background-color: $info-color;
            }
        }

        .modal-footer{
            button{
                text-transform: uppercase;

                &:first-child{
                    opacity: .5;
                }
            }
        }
    }

    &.modal-default{
        @include modal-colors($white-color, $black-color);
    }

    &.modal-primary{
        @include modal-colors($primary-color, $white-color);
    }

    &.modal-danger{
        @include modal-colors($danger-color, $white-color);
    }

    &.modal-warning{
        @include modal-colors($warning-color, $white-color);
    }

    &.modal-success{
        @include modal-colors($success-color, $white-color);
    }

    &.modal-info{
        @include modal-colors($info-color, $white-color);
    }

    &.show.modal-mini .modal-dialog{
        -webkit-transform: translate(0,100%);
        -o-transform: translate(0,100%);
        transform: translate(0,100%);
    }

    .modal-header .close{
        color: $danger-color;
        text-shadow: none;
        padding: 0;
        margin: -1.4rem 0rem -1rem auto;
        opacity: 0.8;

        &:hover,
        &:focus{
            opacity: 1;
        }
    }
}
