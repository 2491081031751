.section{
    padding: 0;
    position: relative;
    background: $white-color;

    .row + .category{
        margin-top: $margin-base-vertical;
    }
}

.page-header{
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: $white-color;
  position: relative;
  overflow: hidden;

  & > .content{
    margin-top: 12%;
    text-align: center;
    margin-bottom: 50px;
  }

  &.page-header-small{
      min-height: 60vh;
      max-height: 440px;
  }

  &:before{
      background-color: rgba(0,0,0,.3);
  }

  > .container{
    z-index: 2;
    padding-top: 12vh;
    padding-bottom: 40px;
  }

    .page-header-image{
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0px -80px;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .content-center{
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      color: #FFFFFF;
      padding: 0 15px;
      width: 100%;
      max-width: 880px;

    }

    footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    .container{
        height: 100%;
        z-index: 1;
        text-align: center;
        position: relative;
    }

    .category,
    .description{
      color: $opacity-8;
    }

    &:after,
    &:before{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
    }
}

[data-background-color="orange"]{
    background-color: $orange-bg;
}
[data-background-color="black"]{
    background-color: $black-color;
}

[data-background-color]:not([data-background-color="gray"]){
    color: $white-color;

    .title,
    .social-description h2,
    p,
    p.blockquote,
    p.blockquote small{
        color: $white-color;
    }

    .separator{
        background-color: $white-color;
    }

    .navbar.bg-white p{
        color: $default-color;
    }

    h1,h2,h3,h4,h5,h6,a:not(.btn):not(.dropdown-item),
    .icons-container{
      color: $white-color;
    }

    .input-group-text,
    .form-group.no-border .input-group-text,
    .input-group.no-border .input-group-text{
      color: $opacity-8;
    }

    .description,
    .social-description p{
        color: $opacity-8;
    }

    p.blockquote{
        border-color: $opacity-2;
    }

    //radio and checkboxes
    .checkbox label::before,
    .checkbox label::after,
    .radio label::before,
    .radio label::after{
        border-color: $opacity-2;
    }

    .checkbox label::after,
    .checkbox label,
    .radio label{
        color: $white-color;
    }

    .checkbox input[type="checkbox"]:disabled + label,
    .radio input[type="radio"]:disabled + label  {
        color: $white-color;
    }

    .radio input[type="radio"]:not(:disabled):hover + label::after,
    .radio input[type="radio"]:checked + label::after {
        background-color: $white-color;
        border-color: $white-color;
    }

    //inputs
    @include input-coloured-bg($opacity-5, $white-color, $white-color, $transparent-bg, $opacity-1, $opacity-2);

    //buttons
    .btn[class*="btn-outline-"] {
        background-color: $transparent-bg;
        border-color: $opacity-5;
        color: $white-color;

        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus,
        &:active:hover,
        &.active:hover,
        .show > &.dropdown-toggle,
        .show > &.dropdown-toggle:focus,
        .show > &.dropdown-toggle:hover {
            background-color: $transparent-bg;
            border-color: $white-color;
            color: $white-color;
        }
    }
}
