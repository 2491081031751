
[data-background-color="blue"]{
    background-color: $info-color;
}

.page-header{
  &.page-header-small{
    min-height: 60vh;
    max-height: 640px;
  }
}

.page-header{
  &.page-header-mini{
    min-height: 88px;
    max-height: 88px;
  }
}
