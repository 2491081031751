// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
.alert{
    border: 0;
    border-radius: 0;
    color: $white-color;
    padding-top: .9rem;
    padding-bottom: .9rem;

    .alert-link{
      color: $white-color;
      opacity: .8;
    }

    &.alert-success{
        background-color: $success-color-alert;
    }

    &.alert-danger{
        background-color: $danger-color-alert;
        position: absolute;
        width: 1000px;
        margin-top: 78px;        
    }

    &.alert-warning{
        background-color: $warning-color-alert;
    }

    &.alert-info{
        background-color: $info-color-alert;
    }

    &.alert-primary{
        background-color: $primary-color-alert;
    }

    .alert-icon{
        display: block;
        float: left;
        margin-right: 15px;
        margin-top: -1px;
    }

    strong{
        text-transform: uppercase;
        font-size: 12px;
    }

    i.fa,
    i.rainforest-ui-icons{
        font-size: 20px;
    }

    .close{
        color: $white-color;
        opacity: .9;
        text-shadow: none;
        line-height: 0;
        outline: 0;
        display: inline-block;
        cursor: pointer;

    }
}

.modal-open {
    #root {
        .alert {
            display: none;
        }
    }
}

.modal {
    .alert {
        padding: 10px 1rem;
        margin: 10px 0px;
        border-radius: 8px;
        position: unset;
        width: unset; 
        p {
            margin-bottom: 0px;
        }
    }
}