.Student-page{
    background-color: #333333;
    .button-white{
        border: 3px solid grey;
        border-radius: 15px;
        min-width: 150px;
        font-size: 12px;
        font-weight: bold;
        background-color: white;
        color: #7d4d1c;
    }
    .arrowNext{
        transform: rotate(0deg);
        position: absolute;
        z-index: 10;
        right: 40px;
        bottom: 20px;
        height: 120px;    
    }
    .arrowPrevious{
        position: absolute;
        z-index: 10;
        left: 40px;
        bottom: 20px;
        height: 120px;
    }
    .activity-tick{
        position: absolute;
        z-index: 100;
        bottom: -20px;
        right: -35px;
        width: 150px;
    }
    .activity-row{
        height: 20px;
        background-color: white;
        border-radius: 10px;
        position: relative;
        margin-top: 20px;
        border-top-left-radius: 0;
        border: 3px solid #a6a7a6;
        margin: 0 20px;
        .style{
            background-color: white;
            border: 3px solid #a6a7a6;
            border-bottom: 0;
            margin-bottom: px;
            position: absolute;
            top: -20px;
            height: 20px;
            width: 130px;
            left: -3px;
            padding: 0px 15px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .text{
            position: absolute;
            top: -12px;
            left: 15px;
        }
    }
    .activity-content{
        position: absolute;
        z-index: 10;
        left: 50px;
        top: 30px;
        max-width: 200px;
        font-size: 18px;
        font-weight: bold;
        .activity-list{
            list-style-type: none;
            padding: 0;
            li{
                font-size: 12px;
            }
        }
    }
    .link{
        cursor: pointer;
    }
    .flip-right{
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
    .title {
        justify-content: center;
        text-align: center;
        padding: 4px;
        border: 3px solid white;
        background-color: white;
        margin: 10px 25px 20px 25px;
        border-radius: 10px;
        font-weight: bold;
        font-size: larger;
        width: 100%;
    }
    .title-md-blue{
        display: flex;
        justify-content: center;
        width: 100%;
        height: 58px;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 7px solid #023fa5;
        background-color: #00ceff;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 20px;
        font-weight: bold;
        font-size: larger;
    }
    .title-lg-blue {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 75px;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 7px solid #023fa5;
        background-color: #00ceff;
        margin-top: 10px;
        border-radius: 15px;
        font-weight: bold;
        font-size: 25px;
    }
    .title-md {
        width: 100%;
        height: 58px;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 4px solid #d9d9c9;
        background-color: white;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 20px;
        font-weight: bold;
        font-size: larger;
        div{
            height: 40px;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 2px solid #dadada;
            background-color: white;
            margin: 5px;
            padding-top: 5px;
            border-radius: 15px;
            font-weight: bold;
            font-size: large;
        }
    }
    .title-lg {
        width: 100%;
        height: 75px;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 4px solid #d9d9c9;
        background-color: white;
        margin-top: 10px;
        border-radius: 15px;
        font-weight: bold;
        font-size: larger;
        div{
            height: 57px;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 2px solid #dadada;
            background-color: white;
            margin: 5px 5px 0 5px;
            padding-top: 10px;
            border-radius: 10px;
            font-weight: bold;
            font-size: larger;
        }
    }
    .rescue-level{
        .arrowRight{
            position: absolute;
            z-index: 10;
            right: 30px;
            top: 250px;
            height: 100px;
        }
        .arrowLeft{
            position: absolute;
            z-index: 10;
            left: 30px;
            top: 250px;
            height: 100px;
        }
        .just-img{
            margin-top: 50px;
            margin-left: 10px;
            display: flex;
            justify-content: center
        }
        .box-title{
            position: absolute;
            top: 10px;
            left: 30px;
        }
        .box-content{
            h5{
                font-size: 18px;
                font-weight: bold;
            }
            p{
                font-size: 12px;
            }
            position: absolute;
            z-index: 50;
            max-width: 175px;
            top: 50px;
            left: 30px;
        }
    }
    .chapter{
        .box-content{
            max-width: 165px;
            p{ 
                font-weight: bold;
                font-size: 14px;
            }
        }
    }
    .box-title{
        position: absolute;
        top: 30px;
        left: 150px;
    }
    .box-title-blue{
        position: absolute;
        z-index: 100;
        top: 18px;
        left: 50px;
    }
    .box-content{
        position: absolute;
        z-index: 50;
        max-width: 150px;
        top: 70px;
        left: 150px;
    }
    .box-content-blue{
        position: absolute;
        z-index: 50;
        max-width: 150px;
        bottom: 35px;
        left: 50px;
    }
    .box-radius-grey{
        border: 3px solid lightgrey;
        border-radius: 32px;
        text-align: center;
        padding: 5px;
    }
    .box-radius-blue{
        border: 3px solid #0339a3;
        border-radius: 32px;
        text-align: center;
        padding: 5px;
        background-color: #00ccff;
        color: #005eda;
    }
    .box-white{
        border: 3px solid grey;
        border-radius: 15px;
        min-height: 310px;
        font-weight: bold;
        background-color: rgb(226, 220, 220);
        color: #7d4d1c;
        max-width: 470px;

        .box{
            margin: 5px;
            border-radius: 10px;
            border: 3px solid #feffec;
            background-color: #feffec;
            min-height: inherit;
            line-height: initial;
        }
        .box-content{
            min-height: 260px;
            .box-radius-grey{
                position: absolute;
                bottom: 5px;
                min-width: 95%;
            }
        }
    }
    .box-blue{
        border: 8px solid #0339a3;
        border-radius: 15px;
        min-height: 280px;
        font-weight: bold;
        max-width: 450px;
        .box{
            border-radius: 5px;
            background-color: #0091f8;
            min-height: inherit;
            line-height: initial;
        }
        .box-title{
            background-color: #00ccff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        .box-content{
            min-height: 240px;
            .box-radius-blue{
                position: absolute;
                bottom: 5px;
                min-width: 95%;
            }
        }
    }
    .class-choose{
        .name{
            position: absolute;
            bottom: 32px;
            left: 50px;
            font-weight: bold;
        }
        .teacher-name{
            position: absolute;
            bottom: 20px;
            left: 50px;
            font-weight: bold;
        }
    }
    .player-choose{
        text-align: -webkit-center;
        .name{
            position: absolute;
            text-align: center;
            width: 150px;
            height: 45px;
            bottom: 20px;
            left: 35px;
            line-height: initial;
            font-weight: bold;
            font-size: 18px;
        }
        .name-module{
            left: 190px;
            font-size: 15px;
            width: 120px;
            height: 35px;
        }

        .bg-player {
            width: 170px;
            height: 170px;                                    
            margin-left: 149px;
            background-size: cover;

            .name{
                text-align: center;
                width: 170px;
                left: unset;
                font-weight: bold;
                padding: 0px 10px;
            }
            .name-module{
                position: absolute;
                font-size: 14px;
                line-height: 1.2;
                height: 47px;
                bottom: 13px;
                display: flex;      
            }            
        } 
        
        .bg-player_list {
            width: 194px;
            height: 194px;                                    
            background-size: cover;

            .name{
                text-align: center;
                width: 194px;
                left: unset;
                font-weight: bold;
                padding: 0px 20px;
            }
            .name-module{
                height: 55px;
                bottom: 15px;
                display: flex;     
            }             
        }
    }
    .class-box {
        // border: 5px solid yellow;
        /* border-color: yellow; */
        border-radius: 15px;
        padding: 15px 15px 15px 15px;
        background-color: white;
        min-width: 370px;
        max-width: 500px;
        border-radius: 30px;

        .title {
            justify-content: center;
            text-align: center;
            padding: 4px;
            border: 3px solid grey;
            margin: 10px 25px 20px 25px;
            border-radius: 25px;
            font-weight: bold;
            font-size: larger;
        }

        .input-group {
            margin-bottom: 0;
        }

        table {
            .actions{
                padding: 0 0 0 1em;
                text-align: end;
                padding-right: 0px;
            }

            .add-class {
                margin-top: 1rem;
            }
        }

        input[type="text"]:disabled {
            cursor: pointer;
        }
    }

    .img-icon {
        height: 25px;
    }
}

