img{
    max-width: 100%;
    border-radius: 1px;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.rf-icon {
    width: 2.6rem;
    height: auto;
}
