@include form-control-placeholder($default-color, .8);

.form-control {
    background-color: $white-bg;
    border: 5px solid $dark-gray;
    border-radius: $btn-round-radius !important;
    color: $black-color;
    height: auto;
    line-height: normal;
    font-size: $font-size-large;
    @include transition-input-focus-color();
    @include box-shadow(none);

    &.square {
        border-radius: $border-radius-extreme !important;
    }

    .has-success &{
        border-color: $light-gray;
    }

    &:focus{
        border: 5px solid $dark-green;
        @include box-shadow(none);
        outline: 0 !important;
        color: $black-color;

        & + .input-group-text,
        & ~ .input-group-text{
            border: 1px solid $primary-color;
            border-left: none;
            background-color: $transparent-bg;
        }
    }

    &.has-error{
        border-color: lighten($danger-color, 5%);
    }

    &.has-error:focus{
        border: 5px solid $dark-green;
    }    

}

@include input-lg-padding($padding-large-vertical, $padding-input-horizontal);
@include input-base-padding($padding-input-vertical, $padding-input-horizontal);
@include input-sm-padding($padding-small-vertical, $padding-small-horizontal);

.input-group,
.form-group{
    margin-bottom: 10px;
    position: relative;
}
.input-group[disabled]{
    .input-group-text{
        background-color: $light-gray;
    }
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: $light-gray;
    color: $default-color;
    cursor: not-allowed;
}

.input-group-btn .btn{
    border-width: $border-thin;
    padding: $padding-btn-vertical  $padding-base-horizontal;
}

.dashed {
    border-style: dashed !important;
}
