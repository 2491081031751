.Class-page {   
    .class-box {
        border: 5px solid yellow;
        /* border-color: yellow; */
        border-radius: 15px;
        padding: 15px 15px 15px 15px;
        background-color: white;
        min-width: 370px;
        max-width: 450px;
        border-radius: 30px;
        margin: auto;
    
        .title {
            justify-content: center;
            text-align: center;
            padding: 4px;
            border: 3px solid grey;
            margin: 10px 25px 20px 25px;
            border-radius: 25px;
            font-weight: bold;
            font-size: larger;
        }
    
        .input-group {
            margin-bottom: 0;
        }
    
        table {
            .actions,
            .delete-action{
                text-align: end;
                padding-right: 0px;
                width: 50px !important;
            }

            .delete-action {
                width: 34px !important;
            }
    
            .add-class {
                margin-top: 1rem;
            }
        }
    
        input[type="text"]:disabled {
            cursor: pointer;
        }

        .disable {
            //opacity: 0.5; /* Diminue l'opacité pour donner un effet désactivé */
            background-color: #e3e3e3; /* Couleur de fond typique pour un champ désactivé */
            color: #888; /* Couleur du texte typique pour un champ désactivé */
            cursor: pointer; /* Change le curseur pour indiquer que l'élément est désactivé */
            caret-color: transparent; 
        }

        .enable {
            //opacity: 1; /* Rétablit l'opacité à la normale */
            background-color: #fff; /* Couleur de fond pour un champ activé */
            color: #212529; /* Couleur du texte normale pour un champ activé */
            cursor: default; /* Change le curseur pour l'édition de texte */
            caret-color: black; 
        }
    }    
}
