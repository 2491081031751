.footer{

    &.footer-default{
        background-color: white;
    }

    nav{
        display: inline-block;
        float: left;
    }

    .fixed-foot {
        background-image: url("../../../theme/images/baseImage.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        // height: 370px;
        min-height: 270px;
        max-height: 370px;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        margin-bottom: 0px;
        //box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.5);
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .subfooter {
        min-height: 150px;
        // background-color: #bbe886;
        .title {
            color: $info-color;
            font-weight: bold;
            font-size: 1.35rem;
            // text-shadow: 1px 1px grey;
        }
        p {
            color: #3e3c3c;
            font-size: 14px;
        }
        a {
            font-size: 1rem;
            color: #3e3c3c;
        }
        .social i {
            font-size: 1.8rem;    
            padding-top: 0.5rem;
        }
        padding: 10px 20px;
    }

    .copyright{
        padding: 10px 0;
        font-size: $font-size-small;
        color: $dark-green;
    }

    .copyright a{
        color: $dark-green;
    }    

    &:after{
        display: table;
        clear: both;
        content: " ";
    }

    @media screen and (min-width: 517px) {
        &.footer.sticky {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }    
}
