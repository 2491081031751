.home-page{
    //background-color: $white-color !important;

    .navbar {
        &.navbar-transparent{
            //padding-top: 20px !important;
        }
    }

    .home-container {      
        max-width: 960px;
        height: 100vh;
        padding: 0;

        .home2 {
            background-image: url("../../../images/mainImage.png");  
            background-size: cover;    
            text-align: center;
            width: 1024px;
            position: absolute;
            // bottom: 0;
            padding-top: 575px;

            img {
                width: 840px;
            }             
            @media all and (max-height: 800px) {
                img {
                    width: 640px;
                }
            }  
        }

        a:hover {
            color:#2b2506;
            font-weight: bold;
        }
    }


    .ibox{
        width: 100%;
        height: 228px;
        margin-left: 10px;
        margin-right: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 5px 15px;
        color: #B22400;

        -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));

        p {
            font-size: 18px;
        }
    }

    .wrapper {
        //background-image: url("../../../images/bkrc.jpg");
        //background-image: url("../../../images/mainImage.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0px -40px;

        padding-bottom: 0px;
    }

    .page-header {
        min-height: 30vh !important;

        &::before{
            background-color: unset;
        }

        .home-title {
            margin: auto;
            font-size: 4rem;
            justify-content: center;
            text-align: center;
            margin-top: 120px;
        }
    }

    .sign-up {
        color: #a09b9b;
        padding: 10px 0px 5px 0px;
        background-color: $dark-green;
        border-radius: 15px;
    }
      
    // input[type='password'].form-control {
    //     font-family: "fontello" !important;
    //     font-size: 24px;
    //     line-height: 26px;
    //     font-weight: bold;
    //     padding: 9px 18px 9px 18px;

    //     &::-webkit-input-placeholder {
    //         font-family: "Autour One", sans-serif;
    //         font-size: 14px !important;
    //         margin: 0px 18px 20px 18px !important;
    //     }
    //     &::-moz-placeholder {
    //         font-family: "Autour One", sans-serif;
    //         font-size: 14px !important;
    //         opacity: 1;
    //         padding: 6px 18px 12px 18px !important;
    //     }
    //     &:-ms-input-placeholder {
    //         font-size: 14px !important;
    //         font-family: "Autour One", sans-serif;
    //         padding: 6px 18px 12px 18px !important;
    //     }
    // } 
   
	// input[type="password"] {
	// 	font-family: "fontello" !important;
	// 	font-style: normal;
	// 	font-weight: normal;
	// 	speak: none;

	// 	/* For safety - reset parent styles, that can break glyph codes*/
	// 	font-variant: normal;
	// 	text-transform: none;

	// 	/* Font smoothing. That was taken from TWBS */
	// 	-webkit-font-smoothing: antialiased;
	// 	-moz-osx-font-smoothing: grayscale;
	// 	/* Uncomment for 3D effect */
	// 	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */

	// 	/* add spacing to better separate each image */
	// 	letter-spacing: 2px;
	// }    
}