
.login-page{
    .page-header:before {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
    }
        
    .card-login{
        border-radius: $border-radius-large;
        padding-bottom: $padding-base-horizontal;
        max-width: 320px;

        .btn-wd{
            min-width: 180px;
        }

        .logo-container{
            width: 65px;
            margin: 0 auto;
            margin-bottom: 55px;

            img{
                width: 100%;
            }
        }

        .input-group:last-child{
            margin-bottom: 40px;
        }

        &.card-plain{
            @include input-coloured-bg($opacity-5, $white-color, $white-color, $transparent-bg, $opacity-1, $opacity-2);

            .input-group-text,
            .form-group.no-border .input-group-text,
            .input-group.no-border .input-group-text{
                color: $opacity-8;
            }
        }
    }
}